import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/render/project/src/src/layouts/Default/index.js";
import { Input, Button, Stack } from '../components';
import { FaPaperPlane, FaExternalLinkAlt } from 'react-icons/fa';
export const metadata = {
  title: 'STACK'
};
export const _frontmatter = {};
const layoutProps = {
  metadata,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1000px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/6f5e040f2efe2cf659e555ad79fbaf83/e8950/stack-2.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "43.99999999999999%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAJCAYAAAAywQxIAAAACXBIWXMAAAsTAAALEwEAmpwYAAACQ0lEQVQoz12SS2sTYRSGB0RR9A8IIoiLLrwW3WhDk0nmYpPMJJNpblJaYpukJC2x1oK2GxeSje4q6k7BtYuqm6oLrwgutII/QCWTuSZpSuuipd/rmaQrFw8fw8d55nvPOVz3wTlsXzjCLDWBhpiHEcnCEDMw+Cy8fBTbjwex82gA69cCMMM6LDEFk7AkDbaUpDPBvIjGfhZ5vH12+hf3un6J4fYAuksyjNhVGEIWTV8opnsCW4zBkeKww1Qsav/JkmhFVPzI6fhw/wzDKw7cH50E+jhrCCTjdRgkaUp9qSmPwpJJKqX2BFpPaovJ/recQJdXsXpzhL1/cRL4vG+XM/gcGjy9KER/zpZhZYowAnE0Qwk0gyrMoAIzRAgkEHyRL0wQKqHAiyTx5dZlbD0/CHw6AM4Qs714TTmH1mIdraU6nMka3PI83OJ1eNN0Ts3CVnKw1Ry8QgWt0izaM/NoVWpoSzrWakP4+vAY8G4/OGeY4gm+MEOFcyS5ASdfgjtRhVsgxqfhjpVImIUTS8Mbm0JrsoJ2uUrCKjqSirWZOJbvRfHt5XFwHxeoL+E0awrUO8IMU+NDFJVXYPFxuiMiMdiCQvRjOhEa0jDFHqIWhJJYD6jMUuL4XQwy7ulyjHlheqFIMrG/FhYNwZK1/orQoCzqry1oNG21hysr2KgN4u/dE9hcOIVO4SI6Ks86whVw3ydScEKjzPQleyvhr4M/TSc6gq1FKrxzHl6C78lsXsPG3FngDddnhVg9ynZXDmHnyeHNf7D1yhprWtKyAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "stack 2",
            "title": "stack 2",
            "src": "/static/6f5e040f2efe2cf659e555ad79fbaf83/00d43/stack-2.png",
            "srcSet": ["/static/6f5e040f2efe2cf659e555ad79fbaf83/63868/stack-2.png 250w", "/static/6f5e040f2efe2cf659e555ad79fbaf83/0b533/stack-2.png 500w", "/static/6f5e040f2efe2cf659e555ad79fbaf83/00d43/stack-2.png 1000w", "/static/6f5e040f2efe2cf659e555ad79fbaf83/aa440/stack-2.png 1500w", "/static/6f5e040f2efe2cf659e555ad79fbaf83/e8950/stack-2.png 2000w"],
            "sizes": "(max-width: 1000px) 100vw, 1000px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <h2>{`$0 Delivery and Service Fees`}</h2>
    <p>{`Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam dignissim risus tempor tellus venenatis,
ut consectetur nulla suscipit. Phasellus metus leo, sollicitudin a tincidunt nec, tempor eu enim.
Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae;
Aliquam sit amet mi sed sem suscipit tempor. Donec placerat elit ac est malesuada,
ut imperdiet felis aliquet. Proin laoreet auctor enim. Morbi scelerisque sem ac risus
convallis, sed luctus nibh aliquet. Vestibulum sit amet neque dignissim, sagittis
justo at, venenatis erat. Duis sit amet dui aliquet lacus posuere hendrerit sed in
lacus. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac
turpis egestas. Nullam sagittis felis vel euismod pharetra. Phasellus id gravida mi, vel tempus
diam. Donec feugiat luctus purus, sit amet posuere dolor viverra non. Interdum et malesuada fames
ac ante ipsum primis in faucibus. Duis sollicitudin efficitur erat at elementum.`}</p>
    <h2>{`What is STACK?`}</h2>
    <p>{`The STACK app provides instant rewards and tools to manage your money.
Our reloadable Mastercard® lets you spend it anywhere worldwide.
No fees, no debt, no catch.`}</p>
    <Stack mdxType="Stack">
    <Button rightIcon={FaExternalLinkAlt} as="a" href="https://www.getstack.ca/" target="_blank" mdxType="Button">
        Learn more
    </Button>
    </Stack>
    <h2>{`How to get started`}</h2>
    <p>{`To get started, enter your email below for your custom code.`}</p>
    <Stack as="form" onSubmit={event => event.preventDefault()} wrap="wrap" mdxType="Stack">
    <Input style={{
        width: '18rem'
      }} placeholder="Your email address" type="email" mdxType="Input" />
    <Button color="primary" variant="solid" leftIcon={FaPaperPlane} mdxType="Button">
        Continue
    </Button>
    </Stack>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      